import React from 'react';

import {
  Call,
  Room,
  Water,
  CheckRounded,
  CloseRounded,
  DirectionsWalk
} from '@mui/icons-material';

import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { ServiceTemplate } from '../components/templates/Service';

export default function CareView() {
  const { author, siteNavigation } = useSiteMetadata();

  const ref = siteNavigation.services[2].ref;
  const name = siteNavigation.services[2].title;

  const data = {
    bannerImages: [{
      filename: `${ref}1.jpg`,
      alt: 'Packaging of the Buddy device',
    }, {
      filename: `${ref}2.jpg`,
      alt: 'A view of the Buddy watch',
    }, {
      filename: `${ref}3.jpg`,
      alt: 'A view of the Buddy device',
    }],
    section1: {
      title: 'Buddy, un dispositif simple pour toutes et tous',
      titleImage: {
        filename: `${ref}_titleImage1.png`,
        alt: 'A view of the Buddy device',
      },
      subtitle1: 'Buddy c\'est quoi ?',
      slogan1: 'Un appareil léger, discret et simple.',
      content1: 'Le Buddy intègre un GPS qui vous localise où que vous soyez et dispose également d\'un détecteur de chute. Buddy vous offre aussi la possibilité de créer un périmètre de déplacements et créer ainsi une zone de sécurité pour les personnes atteintes d\'Alzheimer ou les enfants jouant à l\'extérieur par exemple.',
      imagesContent: [{
        filename: `${ref}_imageContent1.jpg`,
        alt: 'An elderly lady wearing the Buddy watch',
      }, {
        filename: `${ref}_imageContent2.jpg`,
        alt: 'The Buddy device is attached to the belt of a child playing baseball',
      }, {
        filename: `${ref}_imageContent3.jpg`,
        alt: 'The Buddy device is attached to a woman\'s backpack',
      }, {
        filename: `${ref}_imageContent4.jpg`,
        alt: 'A man wearing the Buddy watch',
      }],
      subtitle2: 'Pour qui est fait Buddy ?',
      slogan2: 'Tout le monde peut l\'utiliser.',
      content2: 'Conçu aussi bien pour les jeunes que les moins jeunes, Buddy accompagne les personnes âgées, les enfants, les sportifs, les randonneurs, les skieurs, les travailleurs isolés ou encore toute personne exerçant une activité en solitaire qui peuvent à tout moment envoyer une alarme en cas d\'urgence.',
    },
    section2: {
      title: 'Buddy, la sécurité en toute liberté',
      titleImage: {
        filename: `${ref}_titleImage2.png`,
        alt: 'The Buddy device held by a hand',
      },
      subtitle1: 'Comment fonctionne Buddy ?',
      slogan1: 'Un simple bouton suffit.',
      content1: 'En cas d\'urgence, appuyer sur le bouton SOS et un appel ou un SMS se déclenche automatiquement sur le portable du contact que vous avez défini. En cas de non réponse, l\'alerte est dirigée sur le contact suivant et ainsi de suite jusqu\'à ce qu\'une personne réponde. Vous pouvez également opter pour un appel simultané sur l\'ensemble des contacts. Dès que l\'appel est quittancé par l\'un des aidants, l\'appel vers les autres aidants est interrompu automatiquement.\nLa programmation du Buddy permet d\'introduire jusqu\'à 4 contacts et un 5e dirigé sur notre centre d\'appels qui assure une permanence 24/7. En cas de chute, l\'alerte est émise sans pression sur le bouton. Si une alarme se déclenche par erreur, il est possible de la stopper dans les 10 secondes qui suivent.',
    },
    advantages: {
      list: [{
        icon: <Call fontSize="large" />,
        content: 'Emission et réception d\'appels',
      }, {
        icon: <DirectionsWalk fontSize="large" sx={{ transform: 'rotate(-60deg)' }} />,
        content: 'Détection des chutes',
      }, {
        icon: <Room fontSize="large" />,
        content: 'Localisation GPS',
      }, {
        icon: <Water fontSize="large" />,
        content: 'Résistant à l\'eau',
      }],
      isInfo: true,
      info: 'Garantie 2 ans, fourni avec un câble magnétique USB, une base de chargement et un tour de cou. Vous pouvez aussi accessoiriser votre Buddy avec : un clip ceinture, un bracelet scratch ou un bracelet montre.',
    },
    pricing: {
      isPricing: true,
      subtitle: 'Le dispositif BUDDY est à CHF 249.- tous frais inclus',
      info: `Il est nécessaire d'avoir un abonnement ${author} pour utiliser l'appareil. Cet abonnement est conclu pour un mois, renouvelable tacitement et sans durée minimum d'engagement.`,
      subscriptions: [{
        title: 'Light',
        price: 'CHF 24.90/mois',
        services: [{
          icon: <CloseRounded color="error" />,
          title: 'Call-center 24/7',
        }],
      }, {
        title: 'Premium',
        price: 'CHF 29.90/mois',
        services: [{
          icon: <CheckRounded color="success" />,
          title: 'Call-center 24/7',
        }],
      }],
    },
    servicesPage: {
      prev: {
        title: siteNavigation.services[1].title,
        url: siteNavigation.services[1].url,
      },
      current: {
        title: name,
      },
      next: {
        title: siteNavigation.services[0].title,
        url: siteNavigation.services[0].url,
      },
    },
  };

  return (
    <ServiceTemplate data={data} />
  );
};