import React from 'react';

import Seo from '../../components/seo/Seo';
import CareView from '../../views/CareView';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export default function Care() {
  const { siteNavigation } = useSiteMetadata();

  return (
    <>
      <Seo
        currentTitle={siteNavigation.services[2].title}
        currentDescription={siteNavigation.services[2].description}
      />
      <CareView />
    </>
  );
};
